import React from 'react'

const BannerLanding = props => {
  return (
    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>{props.title}</h1>
        </header>
        {props.subText && (
          <div className="content">
            <p>{props.subText}</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default BannerLanding
