import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const step1 = require('../assets/images/iOS_update1.png')
const step2 = require('../assets/images/iOS_install2.png')

export default () => {
  const { t } = useTranslation()
  return (
    <div className="inner">
      <header className="major">
        <h2>{t('ios.update.step1')}</h2>
      </header>
      <p>{t('ios.update.step1a')}</p>
      <header className="major" style={{ marginTop: 20 }}>
        <h2>{t('ios.update.step2')}</h2>
      </header>
      <img
        src={step2}
        style={{
          width: 300,
        }}
      />
    </div>
  )
}
