import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import IOSInstall from '../components/IOSInstall'
import IOSUpdate from '../components/IOSUpdate'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { navigate } from 'gatsby'

const Landing = props => {
  const [activeButton, setActiveButton] = useState('install')
  const { t } = useTranslation()

  useEffect(() => {
    navigate('/')
  }, [])

  useEffect(() => {
    const searchProps = props.location.search
    const regex = /default=(.*?)(?:&|$)/g
    const defaultProp = regex.exec(searchProps)
    if (defaultProp !== null) {
      setActiveButton(defaultProp[1])
    }
  }, [props.location.search])

  return (
    <Layout>
      <Helmet>
        <title>{t('iosSetup')}</title>
        <meta name="description" content={t('iosSetup')} />
      </Helmet>

      <BannerLanding title="iOS setup" subText={t('stepByStep')} />

      <div id="main">
        <section id="one">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <button
              style={{
                borderColor: 'white',
                backgroundColor:
                  activeButton === 'install' ? '#21939c' : 'transparent',
              }}
              onClick={() => {
                setActiveButton('install')
              }}
            >
              {t('install')}
            </button>
            <button
              style={{
                borderColor: 'white',
                backgroundColor:
                  activeButton === 'update' ? '#21939c' : 'transparent',
              }}
              onClick={() => {
                setActiveButton('update')
              }}
            >
              {t('update')}
            </button>
          </div>
        </section>
        <section
          id="two"
          style={{ display: activeButton === 'install' ? '' : 'none' }}
        >
          <IOSInstall />
        </section>
        <section
          id="three"
          style={{ display: activeButton === 'update' ? '' : 'none' }}
        >
          <IOSUpdate />
        </section>
      </div>
    </Layout>
  )
}

export default Landing
